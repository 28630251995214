<h1 mat-dialog-title style="font-weight: bold; font-family: latoRegular,sans-serif !important;">RSVP to Meeting</h1>
<div mat-dialog-content style="padding-top: 10px;">
  <p style="font-family: latoRegular,sans-serif !important;">Let meeting invitees know if you plan to attend the meeting.</p>
  <br />
  <strong style="color: #4b9539; font-family: latoRegular,sans-serif !important;">RSVP Status</strong>
  <mat-form-field appearance="standard" class="example-full-width">
    <select matNativeControl (change)="choice = $event.target.value">
      <option value="Attending" style="font-family: latoRegular,sans-serif !important;">Attending</option>
      <option value="Attending Remotely" style="font-family: latoRegular,sans-serif !important;">Attending Remotely</option>
      <option value="Not Attending" style="font-family: latoRegular,sans-serif !important;">Not attending</option>
    </select>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()" color="warn" style="font-family: latoRegular,sans-serif !important;">Cancel</button>
  <button mat-button (click)="onSubmit()" color="primary" cdkFocusInitial style="font-family: latoRegular,sans-serif !important;">
    Submit
  </button>
</div>
