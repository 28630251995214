<mat-card class="agenda_card">
    <div class="heading">
        <mat-card-title>Agenda Open Book</mat-card-title>
        <i style="color: #2c2447; font-size: 30px;" class="fa fa-folder-open" aria-hidden="true"></i>
        <p>
          There are no current updates for Agendas
        </p>
        
      </div>
</mat-card>

