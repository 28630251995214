<div class="data">
  <div class="heading-section">
    <div class="meeting-heading">
      <h1><b>System license overview</b></h1>

      <mat-card-subtitle
        >The system license is described below.</mat-card-subtitle
      >
    </div>
  </div>
  <br />
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <!-- organization Column -->
      <ng-container matColumnDef="organization">
        <th mat-header-cell *matHeaderCellDef>Organization</th>
        <td mat-cell *matCellDef="let element">
          {{ element.organization?.name }}
        </td>
      </ng-container>
      <!-- server_id Column -->
      <ng-container matColumnDef="server_id">
        <th mat-header-cell *matHeaderCellDef>Server ID.</th>
        <td mat-cell *matCellDef="let element">{{ element.server_id }}</td>
      </ng-container>

      <!-- license_key Column -->
      <ng-container matColumnDef="license_key">
        <th mat-header-cell *matHeaderCellDef>License Key</th>
        <td mat-cell *matCellDef="let element">{{ element.license_key }}</td>
      </ng-container>

      <!-- license_type Column -->
      <ng-container matColumnDef="license_type">
        <th mat-header-cell *matHeaderCellDef>License Type</th>
        <td mat-cell *matCellDef="let element">{{ element.license_type }}</td>
      </ng-container>

      <!-- license_expiry_period Column -->
      <ng-container matColumnDef="license_expiry_date">
        <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.license_expiry_date | date: "dd - MM - YYYY"}}
        </td>
      </ng-container>

      <!-- number_of_users Column -->
      <ng-container matColumnDef="number_of_users">
        <th mat-header-cell *matHeaderCellDef>Total Users</th>
        <td mat-cell *matCellDef="let element">
          {{ element.number_of_users }}
        </td>
      </ng-container>

      <!-- isActive Column -->
      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef>Is Active</th>
        <td mat-cell *matCellDef="let element">{{ element.isActive }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
