<div class="data">
  <mat-tab-group mat-align-tabs="left">
    <mat-tab label="Organization settings">
      <div class="dashboard-content">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let org of organizationsObj">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b> {{ org?.name }}</b>
              </mat-panel-title>
              <mat-panel-description>
                <p>
                  <b>{{ org?.description }}</b>
                </p>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div *ngIf="!editOrg">
              <div class="logo-container">
                <img src="{{ org?.logo }}" class="logo" alt="" />
              </div>

              <div class="heading-section">
                <span class="gray-color">ORGANIZATION'S INFO</span><br /><br />
                <div *ngIf="!editOrg">
                  <button
                    mat-button
                    class="create-btn"
                    (click)="enableEditOrganization(true, org)"
                  >
                    Edit Details
                  </button>
                </div>
              </div>
              <P
                ><span>Title: </span> <b> {{ org?.name }} </b></P
              >

              <P
                ><span>Registration ID: </span>
                <b> {{ org?.company_registration_number }}</b></P
              >

              <P
                ><span>Address: </span> <b> {{ org?.address }} </b></P
              >
              <P
                ><span>Mission: </span> <br />
                <b> {{ org?.mission }} </b>
              </P>
              <P
                ><span>Vision: </span> <br />
                <b> {{ org?.vision }} </b>
              </P>

              <P
                ><span>Location: </span> <b> {{ org?.time_zone }}</b></P
              >
              <P
                ><span>Country: </span> <br />
                <b> {{ org?.country }} </b></P
              >

              <P
                ><span>City: </span> <br />
                <b> {{ org?.city }} </b></P
              >

              <P
                ><span>State: </span> <br />
                <b> {{ org?.state }} </b></P
              >

              <P
                ><span>Street Address: </span> <br />
                <b> {{ org?.street_address }} </b></P
              >

              <P
                ><span>Postal Code: </span> <br />
                <b> {{ org?.postal_code }} </b></P
              >
            </div>
            <div *ngIf="editOrg" class="editOrg">
              <mat-card class="example-card">
                <mat-card-content>
                  <form
                    [formGroup]="updateForm"
                    id="UpdateForm"
                    class="example-update-form"
                  >
                    <mat-form-field
                      appearance="outline"
                      class="example-full-width"
                    >
                      <mat-label>Organization Title</mat-label>
                      <input type="text" formControlName="name" matInput />
                    </mat-form-field>

                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>About</mat-label>
                      <textarea
                        matInput
                        matTextareaAutosize
                        matAutosizeMinRows="3"
                        matAutosizeMaxRows="10"
                        formControlName="description"
                      ></textarea>
                    </mat-form-field>

                    <mat-form-field
                      appearance="outline"
                      class="example-full-width"
                    >
                      <mat-label>Venue Address</mat-label>
                      <input type="text" formControlName="address" matInput />
                    </mat-form-field>
                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>Vision</mat-label>
                      <textarea
                        matInput
                        matTextareaAutosize
                        matAutosizeMinRows="3"
                        matAutosizeMaxRows="10"
                        formControlName="vision"
                      ></textarea>
                    </mat-form-field>
                    <mat-form-field
                      class="example-full-width"
                      appearance="outline"
                    >
                      <mat-label>Mission</mat-label>
                      <textarea
                        matInput
                        matTextareaAutosize
                        matAutosizeMinRows="3"
                        matAutosizeMaxRows="10"
                        formControlName="mission"
                      ></textarea>
                    </mat-form-field>
                    <p>
                      <mat-form-field
                        class="example-full-width"
                        appearance="outline"
                      >
                        <mat-label>Country</mat-label>
                        <input matInput formControlName="country" />
                      </mat-form-field>
                      <mat-form-field
                        class="example-full-width"
                        appearance="outline"
                      >
                        <mat-label>State</mat-label>
                        <input matInput formControlName="state" />
                      </mat-form-field>
                      <mat-form-field
                        class="example-full-width"
                        appearance="outline"
                      >
                        <mat-label>City</mat-label>
                        <input matInput formControlName="city" />
                      </mat-form-field>

                      <mat-form-field
                        class="example-full-width"
                        appearance="outline"
                      >
                        <mat-label>Postal Code</mat-label>
                        <input
                          matInput
                          value="Coseke Rwanda"
                          formControlName="postal_code"
                        />
                      </mat-form-field>
                      <mat-form-field
                        class="example-full-width"
                        appearance="outline"
                      >
                        <mat-label>Street Address</mat-label>
                        <input
                          matInput
                          value="Coseke Rwanda"
                          formControlName="street_address"
                        />
                      </mat-form-field>
                    </p>
                  </form>
                </mat-card-content>

                <mat-card-actions align="end">
                  <button
                    mat-button
                    color="warn"
                    (click)="enableEditOrganization(false, 'param')"
                  >
                    Cancel
                  </button>
                  <button
                    form="updateForm"
                    [disabled]="!updateForm.valid"
                    (click)="updateOrganization(updateForm.value)"
                    mat-button
                    color="primary"
                  >
                    Update
                  </button>
                  <mat-spinner
                    diameter="40"
                    class="loading-indicator"
                    *ngIf="loadingUpdate"
                  >
                  </mat-spinner>
                </mat-card-actions>
              </mat-card>
            </div>
          </mat-expansion-panel> </mat-accordion
        ><br />

        <div class="org-contact">
          <mat-card>
            <div class="heading-section">
              <mat-card-title>Contact Details</mat-card-title>
              <div class="create-btns">
                <button
                  mat-button
                  class="create-btn"
                  (click)="CreateContactDialog()"
                >
                  Add Contact details
                </button>
                <button
                  *ngIf="contactsObj?.length"
                  mat-button
                  color="warn"
                  (click)="deleteContact(contactsObj[0]?.id)"
                >
                  Delete
                </button>
              </div>
            </div>
            <div class="main-view">
              <P
                ><span>Website: </span> <b> {{ contactsObj[0]?.website }}</b></P
              >
              <P
                ><span>Phone number: </span>
                <b> {{ contactsObj[0]?.phone_number }}</b></P
              >
            </div>
          </mat-card>
        </div>
        <br />
        <div class="org-social">
          <mat-card>
            <div class="heading-section">
              <mat-card-title>Social Media Details</mat-card-title>
              <div class="create-btns">
                <button
                  mat-button
                  class="create-btn"
                  (click)="CreateContactSocialMediaDialog()"
                >
                  Add Social Media
                </button>
                <button
                  mat-button
                  *ngIf="socialObj?.length"
                  color="warn"
                  (click)="deleteSocial(socialObj[0]?.id)"
                >
                  Delete
                </button>
              </div>
            </div>
            <div class="main-view">
              <P
                ><span>Twitter: </span>
                <b> {{ socialObj[0]?.twitter_link }}</b></P
              >
              <P
                ><span>Facebook: </span>
                <b> {{ socialObj[0]?.facebook_link }}</b></P
              >
            </div>
          </mat-card>
        </div>

        <br />

        <div class="org-leader">
          <mat-card>
            <div class="heading-section">
              <mat-card-title>Board Leadership</mat-card-title>
              <div class="create-btns">
                <button
                  mat-button
                  class="create-btn"
                  (click)="CreateLeadersDialog()"
                >
                  Add board member
                </button>
                <button
                  mat-button
                  color="warn"
                  *ngIf="leadersObj?.length"
                  (click)="deleteBoardMember(leadersObj[0]?.id)"
                >
                  Delete
                </button>
              </div>
            </div>
            <div class="main-view">
              <P
                ><span>CEO/President: </span>
                <b> {{ leadersObj[0]?.ceo }}</b></P
              >
              <P
                ><span>Chairman: </span>
                <b> {{ leadersObj[0]?.chairman }}</b></P
              >
              <P
                ><span>Secretary: </span>
                <b> {{ leadersObj[0]?.secretary }}</b></P
              >
            </div>
          </mat-card>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Ip filtering settings">
      <br />
      <div class="content">
        <div class="heading-section">
          <div class="meeting-heading">
            <h1><b>Ip Address allowed range</b></h1>
            <mat-card-subtitle
              >Add the IP address range allowed in the system.
            </mat-card-subtitle>
          </div>
          <div class="create-btns">
            <button
              mat-stroked-button
              color="primary"
              (click)="AddIpAddressRange()"
            >
              <mat-icon color="primary">add</mat-icon>
              Add IP Address Range
            </button>
          </div>
        </div>
        <br />
        <div class="ip-content-section">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="IP Settings"
              ><br />
              <div class="heading-section">
                <h2>Range</h2>
                <div>
                  <mat-slide-toggle
                    >Enable Ip filtering setting!</mat-slide-toggle
                  >
                </div>
              </div>
              <mat-card class="card-ip" *ngFor="let ip of ipRangesObj">
                <div class="range">
                  <p>
                    Start Ip: <b>{{ ip.ip_start }}</b>
                  </p>
                  <p>
                    End Ip: <b>{{ ip.ip_end }}</b>
                  </p>
                </div>
                <div class="range-action">
                  <button
                    mat-button
                    color="warn"
                    (click)="deleteIpRange(ip.id)"
                    *ngIf="!loading"
                  >
                    Delete
                  </button>
                  <mat-spinner
                    diameter="40"
                    color="warn"
                    class="loading-indicator"
                    *ngIf="loading"
                  >
                  </mat-spinner>
                </div>
              </mat-card>
            </mat-tab>
            <mat-tab label="Audit trail details"
              ><br />
              <h2>Users Actions Details</h2>
              <mat-card>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">User</th>
                      <th scope="col">Ip address</th>
                      <th scope="col">Login time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let audit of auditUserObj">
                      <td>
                        {{ audit.user.first_name }} {{ audit.user.first_name }}
                      </td>
                      <td>{{ audit.ip_address }}</td>
                      <td>{{ audit.login_time | date: "medium" }}</td>
                    </tr>
                  </tbody>
                </table>
              </mat-card>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Features">
      <div class="feature-section">
        <br />
        <div class="heading-section">
          <div class="meeting-heading">
            <h1><b>Zoom Account Settings</b></h1>

            <mat-card-subtitle
              >The system is integrated with Zoom for video conferencing purposes.
            </mat-card-subtitle>
            <mat-card-subtitle>
              <p>Board Meetings</p>
              <span>Admin email: admin@cosekeeboard.com</span>
            </mat-card-subtitle>
          </div>
          <div class="create-btns">
            <button mat-stroked-button [disabled]="true">
              <mat-icon>cast_connected</mat-icon>
              ACTIVE
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="License">
      <div class="license-section">
        <br />
        <app-licenses></app-licenses>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
