import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from './../../api-manager/api-manager.service';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { LicensesService } from 'src/app/services/licenses.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  sessionStorage = window.sessionStorage;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  isAdmin: any = false;
  passwordHide = true;
  expiry_date: any = [];
  org_reference_key = '';
  license: any = '';

  formGroup: FormGroup;
  today = new Date();
  hide = true;
  url : any;
  token: any;

  license_expire_message = 'License Expired, please contact the admin';
  success_login_message = 'You have successfully logged In';
  currentUser: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiManager: ApiManagerService,
    private licenseManager: LicensesService,
    public snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.sessionStorage.clear();
    this.createForm();
    //this.getLicenseExpiry(any);
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  getLicenseExpiry(ref: any) {
    this.license_expire_message = 'License Expired, please contact the admin';
    this.success_login_message = 'You have successfully logged In';
    this.licenseManager.getLicenseByOrgRegNo(ref).subscribe({
        next: (res) => {
          const expiry_date_live = this.expiry_date = res[0].license_expiry_date;
          // const userCount = this.users.length;
          // const users = this.maximum_users = res[0].number_of_users;
          //console.log(users);
          const now = new Date();

          // console.log(res);
  
          const expiryDate = new Date(expiry_date_live);
          
          if (expiryDate.getTime() <= now.getTime()) {
            this.openSnackBar(this.license_expire_message, 'CLOSE');
          } else {
            this.openSnackBar(this.success_login_message, 'CLOSE');
            this.router.navigate(['/admin/landing-page']);
          }
        },
        error: (err) => {
          console.log(err);
          alert('an error occured when fetching licence expiry');
        },
      });
  }

  onSubmit(data: any) {

    this.url = 'http://127.0.0.1:8001/license/get_license_by_org/';

    this.license_expire_message = 'License Expired, please contact the admin';
    this.success_login_message = 'You have successfully logged In';
    const spinner = this.apiManager.startLoading('please wait...');

    // login in the user
    const obs = this.apiManager.login(data).subscribe(
      (response: any) => {
        console.log(response)
        console.log("login response is ----"+response);
        this.apiManager.stopLoading(spinner);

        if(response?.status == "Failed"){
          this.openSnackBar("Unable to send OTP please try again later", 'CLOSE');
          return
        }

        if(response?.error){
          this.openSnackBar(response?.error, 'CLOSE');
          return
        }

          
        if(response?.status == 'Ok'){

          console.log("status is okay moving on to redirection")
          this.apiManager.createtoken(response)

          // token present in browser

          if (!this.sessionStorage.getItem('token')) {
            console.log("session token does not exist")
            this.openSnackBar('Oops! Something went wrong! try again', 'CLOSE');
            return;
          }

          this.token = this.sessionStorage.getItem('token');
          console.log("token presence is -------- "+ this.token);
          
          
          // console.log(this.getLicenseExpiry());
          // console.log(this.sessionStorage.getItem('token'))
          //
          this.currentUser = JSON.parse(sessionStorage.getItem('profile'));
          
        //   const ref = this.currentUser?.org_reference_key;
        //   // console.log(ref);
        //   console.log("url is -----"+this.url+ ref +'/');
        //   // console.log("auth options are -----"+this.httpAuthOptions);


        //   // checking whether licence is expired
        //   this.http.get(this.url + ref +'/',{
        //     headers: new HttpHeaders({
        //       'Content-Type': 'application/json',
        //       Accept: 'application/json',
        //       Authorization: this.token,
        //     }),
        //   }).subscribe({
        //     next: (res) => {

        //       console.log("response when checking organisation licence expiry ---"+ res)
        //       const expiry_date_live = this.expiry_date = res[0].license_expiry_date;
        //       // const userCount = this.users.length;
        //       // const users = this.maximum_users = res[0].number_of_users;
        //       //console.log(users);
        //       const now = new Date();

        //       // console.log(res);
      
        //       const expiryDate = new Date(expiry_date_live);
              
        //       if (expiryDate.getTime() <= now.getTime()) {
        //         this.openSnackBar(this.license_expire_message, 'CLOSE');
        //       } else {
        //         this.openSnackBar(this.success_login_message, 'CLOSE');
        //         this.router.navigate(['/admin/landing-page']);
        //       }
        //     },
        //     error: (err) => {
        //     console.log("login error is ----"+err);
            
        //   },
        // });
        //console.log(this.expiry_date)
        // this.router.navigate(['/admin/landing-page']);

          this.router.navigate(['/verify-user']);
          this.openSnackBar(response?.message, 'CLOSE');
          return
        }
        
        // check whether the token does not exists in browser
        if (response?.status === 'License Exhausted') {
          // checking whether the licence is exhausted
          console.log("opening the snack bar")
          this.openSnackBar(this.license_expire_message, 'CLOSE');
          return;
        }

        
        this.router.navigate(['/admin/landing-page']);
        this.openSnackBar(this.success_login_message, 'CLOSE');
        
        },
      (error: any) => {
        console.log("login error is -----"+error)
        this.apiManager.stopLoading(spinner);
        // alert(error);
        console.log(error);
        this.openSnackBar(error?.message, 'CLOSE');
        // if (error?.error.status === 'Ok')
        //   this.router.navigate(['/verify-user']);
        //   if(error.error.message = 'Verification Code still exists kindly confirm it first'){
        //     this.openSnackBar('Verification Code still exists kindly confirm it first or resend code using the resend button below', 'CLOSE');
        //   }
          
      }
    );
  }
  forgotPassword() {
    this.router.navigate(['/forgot-password-page']);
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
