<h1 mat-dialog-title>Resend email verification code</h1>
<div mat-dialog-content [formGroup]="formGroup" class="form">
  <mat-form-field class="example-full-width" appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" />
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()" color="warn">No Thanks</button>
  <button
    mat-button
    (click)="onSubmit()"
    [disabled]="!formGroup.valid"
    color="primary"
    cdkFocusInitial
  >
    Resend
  </button>
  <mat-spinner diameter="40" class="loading-indicator" *ngIf="loading">
  </mat-spinner>
</div>
