<h2 mat-dialog-title>Delete A User</h2>
<mat-dialog-content class="mat-typography">
  <p>Are you sure you want to delete <b>{{data.username}}</b> ?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="warn" cdkFocusInitial (click)="onSubmit()">
    Delete
  </button>
</mat-dialog-actions>
