<br>
<br>
<br>
<div class="page">
  <div class="profile_2">
    <div class="heading-section">
      <div>
        <button mat-icon-button (click)="_location.back()">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
      </div>
      <h2>Signatories</h2>
    </div>
    <!-- <mat-hint>click on add signature once you select assignee </mat-hint>
    <mat-hint>You will be required to move the signture field to where you need the signture to be by clicking on the draw tool then hovering over the field</mat-hint> -->
    <mat-form-field appearance="standard">
      <select matNativeControl (change)="signaturename = $event.target.value">
        <option *ngFor="let item of assigneesValues" [value]="item.label">
          {{ item.label }}
        </option>
      </select>
      
    </mat-form-field>
    <br />

    <div
      draggable="true"
      (dragstart)="dragStart($event)"
      (dragover)="dragOver($event)"
      (dragend)="dragEnd($event, 'SIGNATURE')"
      role="listitem"
    >
      <br />
      <button mat-button [disabled]="!signaturename" (click)="addTextEditor()">
        <mat-icon>drag_indicator</mat-icon> Add signature
      </button>
    </div>
    <br />
    <br />
    <br />
    <button class="save-btn" [disabled]="exported" mat-button (click)="exportannotations()">
      <mat-icon>done</mat-icon>
      Save & finish
    </button>
  </div>

  <!-- <div #viewer class="viewer"></div> -->
  <ngx-extended-pdf-viewer 
  class="viewer"
  [src]="pdfSrc"
  [showToolbar]="showToolbar"
  [showSidebarButton]="false"
  [showFindButton]="true"
  [showPagingButtons]="false"
  [showDrawEditor]="true"
  [showStampEditor]="false"
  [showTextEditor]="false"
  [showZoomButtons]="false"
  [showPresentationModeButton]="false"
  [showOpenFileButton]="false"
  [showPrintButton]="false"
  [showDownloadButton]="false"
  [showSecondaryToolbarButton]="true"
  [showRotateButton]="false"
  [showHandToolButton]="false"
  [showScrollingButton]="false"
  [showSpreadButton]="false"
  [showPropertiesButton]="false">

  </ngx-extended-pdf-viewer>
</div>
