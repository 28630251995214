<div class="data">
  <mat-card class="card">
    <div class="heading-section">
      <div class="back-header2">
        <button mat-icon-button (click)="back()">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
        <h1>New Compliance</h1>
      </div>

      <button
        form="ComplianceForm"
        [disabled]="!formGroup.valid"
        class="save"
        mat-flat-button
        color="primary"
      >
        Save Changes
      </button>
    </div>

    <br />
    <mat-card-content>
      <form
        class="example-form"
        [formGroup]="formGroup"
        id="ComplianceForm"
        (ngSubmit)="onSubmitCompliance(formGroup.value)"
      >
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Compliance Name</mat-label>
          <input
            matInput
            value="New Meeting"
            formControlName="compliance_name"
          />
        </mat-form-field>

        <p>
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Open Date</mat-label>
            <input
              matInput
              [ngxMatDatetimePicker]="picker1"
              formControlName="compliance_start_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker color="primary" #picker1>
            </ngx-mat-datetime-picker>
          </mat-form-field>

          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Close Date</mat-label>
            <input
              matInput
              [ngxMatDatetimePicker]="picker"
              formControlName="compliance_end_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker color="primary" #picker>
            </ngx-mat-datetime-picker>
          </mat-form-field>
          <strong>Add Members</strong>
          <app-search-member-dropdown
            class="example-full-width"
            [bankMultiCtrl]="formGroup.get('invitees')"
          ></app-search-member-dropdown>

          <app-search-groups-member
            class="example-full-width"
            [bankMultiCtrl]="groupInvitees"
          >
          </app-search-groups-member>
        </p>
      </form>
      <mat-checkbox class="example-margin"
        >Enable send email notification!</mat-checkbox
      >
    </mat-card-content> </mat-card
  ><br />
  <div class="content">
    <br />
    <mat-card class="card3">
      <h2>Compliance Check List</h2>
      <p>Start by Composing your Compliance checks</p>
    </mat-card>

    <div class="checks" *ngIf="complianceId">
      <div class="input-section">
        <div class="sample-form">
          <form
            id="checkForm"
            [formGroup]="checkForm"
            (submit)="submitChecks(checkForm.value)"
          >
            <div align="end">
              <button
                mat-stroked-button
                color="primary"
                class="add-check-button"
                (click)="addCheck()"
                type="button"
              >
                Add new check
              </button>
            </div>
            <div
              formArrayName="checks"
              *ngFor="
                let check of checkForm.get('checks')['controls'];
                let i_check = index
              "
            >
              <mat-card class="heading-section checks-body">
                <div class="check">
                  <p>Check {{ i_check + 1 }}</p>
                  <div [formGroupName]="i_check">
                    <mat-form-field
                      class="example-full-width"
                      appearance="standard"
                    >
                      <mat-label>Check title</mat-label>
                      <input
                        matInput
                        formControlName="check_name"
                        type="text"
                      />
                    </mat-form-field>
                    <mat-form-field
                      class="example-full-width"
                      appearance="standard"
                    >
                      <mat-label>Check description</mat-label>
                      <textarea
                        matInput
                        matTextareaAutosize
                        matAutosizeMinRows="1"
                        matAutosizeMaxRows="20"
                        formControlName="check_description"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <br /><br />

                <div class="delete-check-btn">
                  <mat-icon
                    (click)="removeCheck(i_check)"
                    color="warn"
                    class="pointer"
                    >backspace</mat-icon
                  >
                </div>
              </mat-card>
            </div>
          </form>
        </div>
      </div>
      <div align="right">
        <button
          mat-flat-button
          color="primary"
          form="checkForm"
          class="save"
          [disabled]="!checkForm.valid"
          type="submit"
        >
          Save Changes
        </button>
        <!-- <mat-spinner diameter="40" class="loading-indicator"></mat-spinner> -->
      </div>
    </div>
  </div>
</div>
