<div class="data">
  <mat-card class="survey-card">
    <div class="heading-section">
      <div class="flex">
        <button mat-icon-button>
          <mat-icon color="primary" class="pointer" (click)="back()"
            >arrow_back_ios_new</mat-icon
          >
        </button>
        <div class="flex-self">
          <h1>New Survey</h1>
        </div>
      </div>

      <div class="save-survey" align="left">
        <button
          form="surveyForm"
          [disabled]="!surveyForm.valid"
          class="save"
          mat-flat-button
          color="primary"
        >
          Save Survey
        </button>
      </div>
    </div>
    <br />
    <mat-card-content>
      <form
        class="example-form"
        [formGroup]="surveyForm"
        id="surveyForm"
        (ngSubmit)="onSubmitSurvey(surveyForm.value)"
      >
        <p>
          <strong>Survey title</strong>
          <mat-form-field class="example-full-width" appearance="outline">
            <input
              matInput
              value="New Meeting"
              formControlName="survey_title"
            />
          </mat-form-field>
          <strong>Survey Description</strong>
          <mat-form-field class="example-full-width" appearance="outline">
            <textarea
              matInput
              formControlName="survey_description"
              required
            ></textarea>
          </mat-form-field>
          <strong>Participants</strong>
          <app-search-member-dropdown
            class="example-full-width"
            [bankMultiCtrl]="surveyForm.get('permissions')"
          ></app-search-member-dropdown>
        </p>

        <p class="space">
          <strong>Open Date</strong>
          <mat-form-field appearance="outline" class="example-full-width">
            <input
              matInput
              [ngxMatDatetimePicker]="picker1"
              formControlName="survey_open_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker color="primary" #picker1>
            </ngx-mat-datetime-picker>
          </mat-form-field>
          <strong>Close Date</strong>
          <mat-form-field appearance="outline" class="example-full-width">
            <input
              matInput
              [ngxMatDatetimePicker]="picker"
              formControlName="survey_close_date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker color="primary" #picker>
            </ngx-mat-datetime-picker> </mat-form-field
          ><br />
          <mat-checkbox class="example-margin"
            >Enable send email notification!</mat-checkbox
          >
        </p>
      </form>
      <div class="attachment-section">
        <ngx-dropzone (change)="onSelect($event)">
          <ngx-dropzone-label>Attach supporting document!</ngx-dropzone-label>
          <ngx-dropzone-preview
            *ngFor="let f of files"
            [removable]="true"
            (removed)="onRemove(f)"
          >
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="content">
    <br />
    <mat-card class="card3">
      <div>
        <h2>Survey Questions</h2>
        <p>Start by Composing your Survey</p>
      </div>
      <div>
        <button
          mat-flat-button
          color="primary"
          form="questionForm"
          class="save"
          [disabled]="!questionForm.valid"
          type="submit"
          *ngIf="survey_id"
        >
          Save Questions
        </button>
        <mat-spinner
          diameter="40"
          class="loading-indicator"
          *ngIf="loading"
        ></mat-spinner>
      </div>
    </mat-card>

    <div class="questions" *ngIf="survey_id">
      <div class="input-section">
        <div class="sample-form">
          <form
            id="questionForm"
            [formGroup]="questionForm"
            (submit)="submitQuestions(questionForm.value)"
          >
            <div
              formArrayName="questions"
              *ngFor="
                let q of questionForm.get('questions')['controls'];
                let i_question = index
              "
            >
              <mat-card class="heading-section question-body">
                <div class="q">
                  <p>Question {{ i_question + 1 }}</p>
                  <div [formGroupName]="i_question">
                    <mat-form-field
                      class="example-full-width"
                      appearance="standard"
                    >
                      <mat-label>Question title</mat-label>
                      <input
                        matInput
                        formControlName="question_title"
                        type="text"
                      />
                    </mat-form-field>

                    <mat-form-field appearance="standard">
                      <mat-label>Question type</mat-label>
                      <mat-select
                        name="options"
                        formControlName="question_type"
                        (selectionChange)="
                          onQTypeChange($event.value, i_question)
                        "
                      >
                        <mat-option value="liner-scale"
                          ><mat-icon color="primary">linear_scale</mat-icon>
                          Linear scale
                        </mat-option>
                        <mat-option value="radio"
                          ><mat-icon color="primary"
                            >radio_button_checked</mat-icon
                          >
                          Multiple choice
                        </mat-option>
                        <mat-option value="checkbox"
                          ><mat-icon color="primary">check_box</mat-icon>
                          Checkbox
                        </mat-option>
                        <mat-option value="short_text"
                          ><mat-icon color="primary">short_text</mat-icon> Short
                          Answer
                        </mat-option>
                        <mat-option value="paragraph"
                          ><mat-icon color="primary">subject</mat-icon>
                          paragraph
                        </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <div
                      formArrayName="responses"
                      *ngFor="
                        let item of q.get('responses')['controls'];
                        let i_item = index
                      "
                    >
                      <div [formGroupName]="i_item" class="heading-section">
                        <mat-form-field
                          class="example-full-width"
                          appearance="standard"
                        >
                          <mat-label>Option {{ i_item + 1 }}</mat-label>
                          <input
                            matInput
                            formControlName="option"
                            type="text"
                          />
                        </mat-form-field>

                        <div class="delete-option-btn">
                          <mat-icon
                            (click)="removeItem(i_question, i_item)"
                            color="warn"
                            class="pointer"
                            >clear</mat-icon
                          >
                        </div>
                      </div>
                    </div>
                    <div class="heading-section1">
                      <div class="add-option-btn">
                        <mat-icon
                          (click)="addItem(i_question)"
                          *ngIf="q.get('responses').length > 0"
                          color="primary"
                          class="pointer"
                          >add</mat-icon
                        >
                      </div>
                      <div class="required-btn">
                        <mat-checkbox
                          class="example-margin"
                          formControlName="required"
                          >Required</mat-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <br /><br />

                <div class="delete-q-btn">
                  <mat-icon
                    (click)="removeQuestion(i_question)"
                    color="warn"
                    class="pointer"
                    >clear</mat-icon
                  >
                </div>
              </mat-card>
            </div>
          </form>
        </div>
        <br />
        <button
          mat-flat-button
          class="primary"
          [disabled]="!survey_id"
          (click)="addQuestion()"
        >
          Add new question
        </button>
        <br />
      </div>
      <br />
    </div>
  </div>
</div>
