<div
  class="container-section"
  fxLayout="row"
  fxLayoutAlign="space-around center"
>
  <div [fxFlex]="(isHandset$ | async) ? '10' : '27.5'"></div>
  <div
    class="login-card-container"
    [fxFlex]="(isHandset$ | async) ? '80' : '45'"
  >
    <mat-card-header>
      <mat-card-title align="center"
        ><b>Forgot your password?</b></mat-card-title
      >

      <mat-card-subtitle>
        <br />
        Don't worry. We will send you the reset instructions.</mat-card-subtitle
      >
    </mat-card-header>

    <mat-card>
      <mat-card-content>
        <form
          [formGroup]="formGroup"
          (ngSubmit)="forgotPassword(formGroup.value)"
          id="ForgotForm"
          class="forgotForm"
        >
          <strong>Your Email</strong>

          <mat-form-field appearance="outline" class="example-full-width">
            <input type="text" formControlName="email" matInput />
          </mat-form-field>
          <br />
          <br />
        </form>
        <button
          form="ForgotForm"
          type="submit"
          mat-flat-button
          class="login-btn"
          [disabled]="!formGroup.valid"
        >
          Send Instructions
        </button>
      </mat-card-content>
      <div align="right" class="right">
        <a routerLink="../login-page"> Back to login </a>
      </div>
    </mat-card>
  </div>
  <div [fxFlex]="(isHandset$ | async) ? '10' : '27.5'"></div>
</div>
