<div>
	<ngx-extended-pdf-viewer 
              class="viewer"
              [src]="pdfSrc" 
              [showToolbar]="showToolbar"
              [showHandToolButton]="true" 
              [showBookmarkButton]="true" 
              [showDownloadButton]="true" 
              [showPrintButton]="true" 
              [showSidebarButton]="true" 
              [showFindButton]="true" 
              [showPagingButtons]="true" 
              [showZoomButtons]="true" 
              [showPresentationModeButton]="true" 
              [showOpenFileButton]="true" 
              [showSecondaryToolbarButton]="true" 
              [showRotateButton]="true" 
              [showScrollingButton]="true" 
              [useBrowserLocale]="true" 
              [textLayer]="true" 
              [annotationLayer]="true" 
              [renderInteractiveForms]="true" 
              [printResolution]="150" 
              [maxCanvasPixels]="10485760">

            </ngx-extended-pdf-viewer>
</div>