
<!--<app-zoom-page class="zoom" *ngIf="showZoomWindow"></app-zoom-page>-->

<!--document view-->

<mat-drawer-container class="example-container" *ngIf="show">
  <mat-card class="card">
  <mat-card class="back-header shadow-lg p-3 mb-5 bg-white rounded">
    <button
      mat-icon-button
      [disabled]="showZoomWindow"
      (click)="_location.back()"
    >
     <mat-icon class="pointer">arrow_back_ios_new</mat-icon>
     Back
     <!-- <div class="flex-self">
      <h2 class="float-lg-start">Back</h2>
    </div> -->
      <!-- <i class="fa fa-step-backward" aria-hidden="true">Back</i> -->
    </button>

  </mat-card>
  <!-- <div class="card">
    <div class="card-body shadow-lg p-3 mb-5 bg-white rounded">

    </div>
  </div> -->
    <div class="card">
    <div class="card-body shadow-lg p-3 mb-5 bg-white rounded">
      <div class="col-md-4">
        <div class="panel" *ngIf="showannotationoptions">
          <div style="background-color: #4b9539; color: white;" class="panel-heading">Annotations Options</div>
          <div class="example-full-width">
            <mat-card>
              <div >
                  <!-- <mat-checkbox class="example-margin"
                  [checked]="allComplete"
                  [disabled]="allann"
                  (change)="allannotations($event.checked)">
                  All Annotatons
                  </mat-checkbox>
                  <mat-checkbox class="example-margin"
                  [checked]="allComplete"
                  [disabled]="sharedann"
                  (change)="sharedannotations($event.checked)">
                  Shared Annotations
                  </mat-checkbox>
                  <mat-checkbox class="example-margin"
                  [checked]="allComplete"
                  [disabled]="viewmyann"
                  (change)="viewmyannotations($event.checked)">
                  My Annotations
                  </mat-checkbox>
                  <mat-checkbox class="example-margin"
                  [checked]="allComplete"
                  [disabled]="hideann"
                  (change)="hidennotations($event.checked)">
                  Hide Annotations
                  </mat-checkbox> -->
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>All Doc Annotations</mat-label>
                    <mat-select
                      class="mat-select-user"
                      #usersList
                      (selectionChange)="displayannotation($event.value)"
                    >
                    <mat-option> ---------------- </mat-option>
                      <mat-option *ngFor="let annotation of alldocannotations" [value]="annotation.xfdf_string"
                        >{{annotation.xfdf_string.annotationType == 3 ? "Text - "+annotation.xfdf_string.value+" - annotated by "+annotation.annoted_by.last_name : "Drawing - annotated by "+annotation.annoted_by.last_name  }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <br>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>All User Shared Annotations</mat-label>
                    <mat-select
                      class="mat-select-user"
                      #usersList
                      (selectionChange)="displayannotation($event.value)"
                    >
                    <mat-option> ---------------- </mat-option>
                      <mat-option *ngFor="let sharedannotation of sharedwithannotations" [value]="sharedannotation.annotation"
                        >{{ sharedannotation.annotation.annotationType == 3 ? 'Text - shared by' : 'Drawing - shared by'  }} {{ sharedannotation.person_initiating_sharing.first_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Annotations Shared with me</mat-label>
                    <mat-select
                      class="mat-select-user"
                      #usersList
                      (selectionChange)="displayannotation($event.value)"
                    >
                    <mat-option> ---------------- </mat-option>
                      <mat-option *ngFor="let sharedannotation of sharedwithmeannotations" [value]="sharedannotation.annotation"
                        >{{ sharedannotation.annotation.annotationType == 3 ? 'Text - shared by' : 'Drawing - shared by'  }} {{ sharedannotation.person_initiating_sharing.first_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>User Shared Annotations</mat-label>
                    <mat-select
                      class="mat-select-user"
                      #usersList
                      (selectionChange)="getsharedannotationsbyuser($event)"
                    >
                      <mat-option *ngFor="let user of sharedwithannotations" [value]="user"
                        >{{ user.annotation.annotationType == 3 ? 'Text - shared by' : 'Drawing - shared by'  }} {{ user.shared_by.first_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field> -->
                  <!-- <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>User Shared Annotations</mat-label>
                    <mat-select
                      class="mat-select-user"
                      #usersList
                      (selectionChange)="getsharedannotationsbyuser($event)"
                    >
                      <mat-option *ngFor="let user of sharedwithannotations" [value]="user"
                        >{{ user.annotation.annotationType == 3 ? 'Text - shared by' : 'Drawing - shared by'  }} {{ user.shared_by.first_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field> -->
                  <!-- {{ user.xfdf_string.annotationType == 3 ? 'Text Annotation' : 'Draw Annotation' }} -->
                  <!-- <div style="justify-content: end;">
                    <mat-icon  class="doc-btn">share</mat-icon>
                  </div> -->
                <br>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="panel">

          <div style="background-color: #4b9539; color: white;" class="panel-heading">Agenda</div>
            <!--<div id="docLinksList" class="list-group">-->


            <div class="example-full-width" *ngIf="items.length">

              <mat-card class="">
                <div class="items" *ngFor="let item of items; let i_item = index">
                  <div class="agenda-input ">
                    <div class="item-input">
                      <div class="flex">
                        <strong matPrefix>{{ romanize(i_item + 1) }} .</strong>
                        <input
                          matInput
                          type="text"
                          value="{{ item.agenda_name }}"
                          [disabled]="true"
                          class="marging-right-15"
                        />
                      </div>
                        <!--<div class="item-details">-->
                        <!--<p class="margin-0" *ngIf="item.presenters">-->
                        <!--presenters: {{ item.presenters }}-->
                        <!--</p>-->
                        <!--<p class="margin-0" *ngIf="item.guests">-->
                        <!--Guests: {{ item.guests }}-->
                        <!--</p>-->
                        <!--</div>-->
                      <p *ngIf="item.agenda_description" class="gray-color">
                        {{ item.agenda_description }}
                      </p>


                      <button
                        mat-icon-button
                        *ngIf="item.agenda_document"
                        type="button"
                        aria-label="add"
                        (click)="openAgendaDocument(item)"
                      >
                        <mat-icon class="doc-btn">description</mat-icon>
                      </button>

                      <button
                        mat-button
                        type="button"
                        aria-label="add"
                        class="edit-btn"
                        *ngIf="currentUser?.org_permission == 'Admin'"
                        (click)="editItemDetailsDialog(item)"
                      >
                        <!-- Edit details-->
                        <i class="fa fa-bars"  aria-hidden="true"></i>
                      </button>

                      <div class="row" *ngIf="item?.agendadocs">
                        <h2><strong>files</strong></h2>
                      </div>
                      <div *ngFor="let f of item?.agendadocs">
                        <mat-card [class]="f.fields.document_name == docname ? 'highlight-doc' : ''" >
                          <div class="row" >
                            <div class="col-lg-12" style="display: flex; align-items: center;">
                              <div class="col-lg-2"><button
                                mat-icon-button
                                *ngIf="item.agenda_document"
                                type="button"
                                aria-label="add"
                                (click)="openagendadoc(item)">
                                <mat-icon class="doc-btn">insert_drive_file</mat-icon>
                              </button></div>
                            <div class="col-lg-8"><span style="font-size: 22px;">{{f.fields.document_name}}</span></div>
                            <button
                                mat-icon-button
                                *ngIf="item.agenda_document"
                                type="button"
                                aria-label="add"
                                (click)="downloadagendadoc(f.fields.agenda_document)">
                            <mat-icon class="doc-btn">download</mat-icon></button>
                            <!-- <div class="col-lg-2" ><a> <mat-icon >delete</mat-icon> </a></div> -->
                            </div>
                          </div>
                        </mat-card>
                      </div>

                    </div>

                  </div>
                  <div
                    class="level_a_items"
                    *ngFor="let item of item?.childreen; let i_item = index"
                  >
                    <div class="agenda-input">
                      <div class="item-input">
                        <div class="flex">
                          <strong matPrefix>{{ leterize(i_item + 1) }} .</strong>
                          <input
                            matInput
                            type="text"
                            value="{{ item.agenda_name }}"
                            [disabled]="true"
                            class="marging-right-15"
                          />
                        </div>
                        <div class="item-details">
                          <p class="margin-0" *ngIf="item.presenters">
                            presenters: {{ item.presenters }}
                          </p>
                          <p class="margin-0" *ngIf="item.guests">
                            Guests: {{ item.guests }}
                          </p>
                        </div>
                        <p *ngIf="item.agenda_description" class="gray-color">
                          {{ item.agenda_description }}
                        </p>
                      </div>
                      <div class=" row details-btn">
                        <button
                          mat-icon-button
                          *ngIf="item.agenda_document"
                          type="button"
                          aria-label="add"
                          (click)="openAgendaDocument(item)"
                        >
                          <mat-icon class="doc-btn">description</mat-icon>
                        </button>
                        <button
                          mat-button
                          type="button"
                          aria-label="add"
                          class="edit-btn"
                          *ngIf="currentUser?.org_permission == 'Admin'"
                          (click)="editItemDetailsDialog(item)"
                        >
                        <!-- Edit details-->
                          <i class="fa fa-align-justify" aria-hidden="true"></i>

                        </button>
                      </div>
                    </div>
                    <div
                      class="level_b_items"
                      *ngFor="let item of item?.childreen; let i_item = index"
                    >
                      <div class="agenda-input">
                        <div class="item-input">
                          <div class="flex">
                            <strong matPrefix>{{ i_item + 1 }}.</strong>
                            <input
                              matInput
                              type="text"
                              value="{{ item.agenda_name }}"
                              [disabled]="true"
                              class="marging-right-15"
                            />
                          </div>
                          <div class="item-details">
                            <p class="margin-0" *ngIf="item.presenters">
                              presenters: {{ item.presenters }}
                            </p>
                            <p class="margin-0" *ngIf="item.guests">
                              Guests: {{ item.guests }}
                            </p>
                          </div>
                          <p *ngIf="item.agenda_description" class="gray-color">
                            {{ item.agenda_description }}
                          </p>
                        </div>
                        <div class="details-btn">
                          <button
                            mat-icon-button
                            *ngIf="item.agenda_document"
                            type="button"
                            aria-label="add"
                            (click)="openAgendaDocument(item)"
                          >
                            <mat-icon class="doc-btn">description</mat-icon>
                          </button>
                          <button
                            mat-button
                            type="button"
                            aria-label="add"
                            class="edit-btn"
                            *ngIf="currentUser?.org_permission == 'Admin'"
                            (click)="editItemDetailsDialog(item)"
                          >
                          <!--Edit details-->
                            <i class="fa fa-align-justify" aria-hidden="true"></i>

                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>

              <br>
              <br>

            </div>
            <!-- </div> &lt;!&ndash; docLinksList &ndash;&gt;-->

        </div> <!-- navPanel -->
      </div> <!-- left column -->

      <div class="col-md-8">
        <div class="panel">
          <div style="background-color: #4b9539; color: white;" id="docName" class="panel-heading"><b>Document</b></div>
          <div id="docContents" class="panel-body">
            <!--                    <iframe id="docPdf" width="100%" height="100%" seamless></iframe>-->
            <div class="view-agenda-doc">
              <div *ngIf="viewAgendaDoc">
                <button mat-button *ngIf="myannotations.length && !showToolbar" (click)="annotatedoc()">
                  <mat-icon color="warn">edit</mat-icon>Annotate
                </button>
                <button mat-button *ngIf="showToolbar" (click)="exportAnnotations()">
                  <mat-icon color="warn">save</mat-icon>Save Annotation
                </button>
                <button mat-button (click)="viewpdf()">
                  <mat-icon color="warn">settings</mat-icon>Annotation Options
                </button>
                <button mat-button (click)="downloadpdfasis()">
                  <mat-icon color="warn">download</mat-icon>Download
                </button>
                <button mat-button (click)="closeagendadoc()">
                  <mat-icon color="warn">close</mat-icon>Close
                </button>
                <!-- <app-view-document
                (close)="viewAgendaDoc = false"
                [pdfSrc] = "pdfSrc"
                class="viewer"
                ></app-view-document> -->


              <br>
              <div *ngIf="showToolbar" style="margin-top: 10px;">
                <h2> Add annotations then click save annotations to save the created annotations</h2>
              </div>
              </div>

              <div *ngIf="showannotationoptions">
                <div class="col-lg-4">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>My annotations to share</mat-label>
                    <mat-select
                      class="mat-select-user"
                      [disabled]="hideann"
                      #usersList
                      (selectionChange)="onselectchange($event.value)"
                    >
                    <mat-option> ------- None --------- </mat-option>
                      <mat-option *ngFor="let user of myannotations" [value]="user"
                        >{{ user.xfdf_string.annotationType == 3 ? 'Text - '+ user.xfdf_string.value : 'Drawing' }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-4">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>choose user</mat-label>
                    <mat-select
                      class="mat-select-user"
                      [disabled]="!annotationselectedforsharing"
                      #usersList
                      (selectionChange)="usertosharewith($event)"
                    >
                      <mat-option *ngFor="let user of permittedusers" [value]="user"
                        >{{ user.first_name }} {{ user.last_name }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-4">
                  <button style="margin-top: 1.5rem;" mat-button (click)="shareannotations()" color="primary">
                    <mat-icon>share</mat-icon> Share
                    </button>
                </div>
                <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              </div>

              <div class="page">



              <ngx-extended-pdf-viewer
              class="viewer"
              [src]="pdfSrc"
              (pageChange)="onEvent('pageChange', $event)"
              [showToolbar]="showToolbar"
              [showHandToolButton]="true"
              [showBookmarkButton]="true"
              [showDownloadButton]="true"
              [showPrintButton]="true"
              [showSidebarButton]="true"
              [showFindButton]="true"
              [showPagingButtons]="true"
              [showZoomButtons]="true"
              [showPresentationModeButton]="true"
              [showOpenFileButton]="true"
              [showSecondaryToolbarButton]="true"
              [showRotateButton]="true"
              [showScrollingButton]="true"
              [useBrowserLocale]="true"
              [textLayer]="true"
              [annotationLayer]="true"
              [renderInteractiveForms]="true"
              [printResolution]="150"
              [maxCanvasPixels]="10485760">

            </ngx-extended-pdf-viewer>

          </div>
            </div>
          </div>
        </div> <!-- contentViewer -->
      </div> <!-- rightCol -->
    </div> <!-- row -->
    </div>
</mat-card>
</mat-drawer-container>





