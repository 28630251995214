<div class="page" *ngIf="pdf">
  <!-- <div #viewer class="viewer"></div> -->

  <ngx-extended-pdf-viewer 
    class="viewer"
    [src]="pdfSrc" 
    [showToolbar]="false"
    [showHandToolButton]="true" 
    [showBookmarkButton]="true" 
    [showDownloadButton]="true" 
    [showPrintButton]="true" 
    [showSidebarButton]="true" 
    [showFindButton]="true" 
    [showPagingButtons]="true" 
    [showZoomButtons]="true" 
    [showPresentationModeButton]="true" 
    [showOpenFileButton]="true" 
    [showSecondaryToolbarButton]="true" 
    [showRotateButton]="true" 
    [showScrollingButton]="true" 
    [useBrowserLocale]="true" 
    [textLayer]="true" 
    [annotationLayer]="true" 
    [renderInteractiveForms]="true" 
    [printResolution]="150" 
    [maxCanvasPixels]="10485760">

  </ngx-extended-pdf-viewer>

</div>
<div *ngIf="image">
  <img [src]="imageUrl" alt="My Image" height="400" width="400">
</div>

<div *ngIf="word">
  <iframe [src]="docUrl" width="600" height="400"></iframe>
</div>
