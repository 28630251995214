<div class="dialog">
  <div class="back-btn">
    <button
      mat-icon-button
      (click)="backClicked()"
      color="primary"
      *ngIf="!isParent"
    >
      <mat-icon>arrow_back_ios_new</mat-icon>
    </button>
    <h1 mat-dialog-title>
      {{parentFolderName? parentFolderName: 'Resources'}}
    </h1>
  </div>
  <div mat-dialog-content>
    <div class="data">
      <div class="example-loading-shade" *ngIf="isLoadingResults">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
      </div>

      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        *ngIf="loadData"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon class="vertical-align" color="primary">folder</mat-icon>
            <span class="margin-bottom">{{ element.name }}</span>
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef>Created</th>
          <td mat-cell *matCellDef="let element">
            <i>{{ element.created_at | date: "medium" }}</i>
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              class="example-icon"
              aria-label="Example icon-button with share icon"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openFolder(element)">
                <span>Open</span>
                <mat-icon color="primary">launch</mat-icon>
              </button>
              <button mat-menu-item (click)="onSubmit(element)">
                <span>Select</span>
                <mat-icon color="primary">check</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
      <h4 *ngIf="!loadData" align="center">This folder is empty</h4>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()" color="warn">Cancel</button>
  </div>
</div>
