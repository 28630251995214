<div class="data">
  <div class="upper-section">
    <div class="middle-section">
      <div>
        <h2>Members Management</h2>
      </div>
      <button mat-button class="create-btn" (click)="registerUserDialog()">
        + Add A Member
      </button>
    </div>
  </div>

  <br />

  <div class="user-view">
    <div class="search-view">
      <mat-form-field
        class="example-full-width resize-form-field"
        appearance="standard"
      >
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          #input
          type="text"
          (keyup)="applyFilter($event)"
          placeholder="Search a member"
          [(ngModel)]="value"
        />
        <button
          *ngIf="value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="value = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="loading-data" *ngIf="users.length">
      <table
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
        matSort
      >
        <!-- Position Column -->
        <ng-container matColumnDef="profile">
          <th mat-header-cell *matHeaderCellDef><span class="members-title">Profile</span></th>
          <td mat-cell *matCellDef="let element">
            <mat-grid-list cols="2" rowHeight="1:1">
              <div class="image-section">
                <img
                class="tile-image"
                src="../../assets/img/profile.png"
                alt="..." 
                class="avatar-img rounded-circle bg-lightgrey"
                style="height: 35px; width: 35px;"
              />
              </div>
                
              
            </mat-grid-list>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span>Name</span></th>
          <td mat-cell *matCellDef="let element">
            {{ element?.first_name }} {{ element?.last_name }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header><span>Email</span></th>
          <td mat-cell *matCellDef="let element">{{ element?.email }}</td>
        </ng-container>

        <!-- is active Column -->
        <ng-container matColumnDef="phone_number">
          <th mat-header-cell *matHeaderCellDef><span>Phone Number</span></th>
          <td mat-cell *matCellDef="let element">
            {{ element.phone_no }}

            <button
              mat-icon-button
              class="example-icon"
              [matMenuTriggerFor]="menu1"
              aria-label="Example icon-button with share icon"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu1="matMenu">
              <button mat-menu-item (click)="editUserDialog(element)">
                <span>Update Member</span>
                <mat-icon color="primary">edit</mat-icon>
              </button>
              <button mat-menu-item (click)="deleteUser(element)">
                <span>Delete Member</span>
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="org_permission">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.org_permission }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
      <div class="paginator">
        <mat-paginator
          [pageSizeOptions]="[5, 10, 30, 50, 1000]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>

    <br />

    <mat-card class="card loading-data">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Groups">
          <mat-card class="groups-view">
            <div class="groups-heading">
              <div>
                <h2>Roles list</h2>
              </div>
              <div>
                <button mat-button color="primary" (click)="addGroupDialog()">
                  Create Group
                </button>
              </div>
            </div>
            <br />
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col" colspan="3">Group details</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="list"
                  *ngFor="let group of allGroupsObj; let i = index"
                >
                  <th scope="row">{{ i + 1 }}</th>
                  <td colspan="2">
                    <strong>{{ group.group_name }}</strong>
                    <p>
                      {{ group.group_description }}
                    </p>
                  </td>
                  <td>
                    <div class="group-action-btns" align="end">
                      <button
                        mat-icon-button
                        class="example-icon"
                        [matMenuTriggerFor]="menu"
                        aria-label="Example icon-button with share icon"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>

                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editGroupDialog(group)">
                          <span>Edit details</span>
                          <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button
                          mat-menu-item
                          (click)="deleteGroupDialog(group.id)"
                        >
                          <span>Delete Group</span>
                          <mat-icon color="warn">delete</mat-icon>
                        </button>
                      </mat-menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-card>
        </mat-tab>

        <mat-tab label="Departments">
          <mat-card class="groups-view">
            <div class="groups-heading">
              <div>
                <h2>Departments List</h2>
              </div>
              <div>
                <button
                  mat-button
                  color="primary"
                  (click)="CreateDepartmentDialog()"
                >
                  Create Department
                </button>
              </div>
            </div>
            <br />
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col" colspan="3">Department Details</th>
                </tr>
              </thead>
              <tbody>
                <tr class="list" *ngFor="let dep of departments; let i = index">
                  <th scope="row">{{ i + 1 }}</th>
                  <td colspan="2">
                    <strong>{{ dep.department_name }}</strong>
                    <p>
                      {{ dep.department_head }}
                    </p>
                  </td>
                  <td>
                    <div class="group-action-btns" align="end">
                      <button
                        [matMenuTriggerFor]="menu"
                        mat-icon-button
                        class="example-icon"
                        aria-label="Example icon-button with share icon"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>

                      <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                          <span>Edit Department</span>
                          <mat-icon color="primary">edit</mat-icon>
                        </button>
                        <button
                          mat-menu-item
                          (click)="deleteDepartment(dep.id)"
                        >
                          <span>Delete Department</span>
                          <mat-icon color="warn">delete</mat-icon>
                        </button>
                      </mat-menu>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </mat-card>
        </mat-tab>

        <mat-tab label="Roles & terms">
          <mat-card class="groups-view">
            <div class="role-terms">
              <strong>Admin</strong>
              <p>
                An Administrator provides support to system users and is vital for the smooth-running of the eCabinet solution. 
                <span>
                  <ul>
                    <li>Create new users.</li>
                    <li>Setting up meetings and loading agendas</li>
                    <li>Setting up approvals, surveys and esignatures</li>
                    <li>Resetting user passwords.</li>
                    <li>Lock/unlock user accounts.</li>
                  </ul>
                </span>
              </p>
            </div>
            <div class="role-terms">
              <strong>Creator</strong>
              <p>
                A creator is a user with priviledges of: 
                <span>
                  <ul>
                    <li>Setting up meetings and loading agendas</li>
                    <li>Setting up approvals, surveys and esignatures</li>
                  </ul>
                </span>
              </p>
            </div>
            <div class="role-terms">
              <strong>Member</strong>
              <p>
                A member is anyone who accesses and uses the eCabinet solution.
              </p>
            </div>
          </mat-card>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</div>
