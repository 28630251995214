<p>
  <mat-form-field class="example-full-width" appearance="outline">
    <mat-select
      [formControl]="bankMultiCtrl"
      class="mat-elevation-z0"
      placeholder="Members"
      [multiple]="true"
      #multiSelect
    >
      <mat-option>
        <ngx-mat-select-search
          [showToggleAllCheckbox]="true"
          (toggleAll)="toggleSelectAll($event)"
          [formControl]="bankMultiFilterCtrl"
          placeholderLabel="Find Member..."
          noEntriesFoundLabel="'no matching member found'"
          [toggleAllCheckboxTooltipMessage]="tooltipMessage"
          [toogleAllCheckboxTooltipPosition]="'above'"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let bank of filteredBanksMulti | async"
        [value]="bank.id"
      >
        {{ bank.first_name }} {{ bank.last_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</p>
