import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { data } from 'jquery';
import { ApiManagerService } from '../api-manager/api-manager.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {
  meeting_id : number;
  constructor(private apiManager: ApiManagerService, private router: Router) { }

  ngOnInit(): void {
    this.getUpcomingMeetingAgenda();
    
  }

  getUpcomingMeetingAgenda() {
    this.apiManager.getActiveMeetings().subscribe(
      data => {
        this.meeting_id = data[0].id
        // this.getMeetingAgendas(this.meeting_id);
        this.router.navigate(['admin/meeting-openbook'], {state: {meeting_id : this.meeting_id}});
        console.log(this.meeting_id)
      },
      error=> {

      }
    )
      
    
  }
  getMeetingAgendas(id: number) {
    this.apiManager.getMeetingAgendas(id).subscribe(
      data => {
        console.log(data)
      },
      error=> {

      }
    )
  }
}
