<div class="data">
  <div class="upper-section">
    <div class="middle-section">
      <div>
        <h2>Compliances List</h2>
      </div>
      <button
        mat-button
        [disabled]="!(currentUser?.org_permission == 'Admin')"
        class="create-btn"
        routerLink="/admin/compliance-creator"
      >
        + Create Compliance
      </button>
    </div>
  </div>

  <br />
  <div class="loading-data" *ngIf="complianceObj.length">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="data-header">Compliance Title</th>
        <td mat-cell *matCellDef="let element" class="data-content">
          {{ element.compliance_name }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef class="data-header">Score</th>
        <td mat-cell *matCellDef="let element" class="data-content">
          {{ element.compliance_score }}%
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="start_date">
        <th mat-header-cell *matHeaderCellDef class="data-header">Open Date</th>
        <td mat-cell *matCellDef="let element" class="data-content">
          {{ element.compliance_start_date | date: "medium"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="end_date">
        <th mat-header-cell *matHeaderCellDef class="data-header">Close Date</th>
        <td mat-cell *matCellDef="let element" class="data-content">
          {{ element.compliance_end_date | date: "fullDate" }}
          <button
            mat-icon-button
            class="example-icon"
            [matMenuTriggerFor]="menu1"
            aria-label="Example icon-button with share icon"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu1="matMenu">
            <button mat-menu-item (click)="openCompliance(element)">
              <span>Open Compliance</span>
              <mat-icon color="primary">launch</mat-icon>
            </button>
            <button
              mat-menu-item
              [disabled]="!(currentUser?.org_permission == 'Admin')"
              (click)="deleteCompliance(element.id)"
            >
              <span>Delete Compliance</span>
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>
    <div class="paginator">
      <mat-paginator
        [pageSizeOptions]="[4, 8, 15, 30, 50, 1000]"
        showFirstLastButtons
      ></mat-paginator>
    </div>
    <br />
  </div>
</div>
