<div class="wrapper" style="background-color: white">
  <div id="left">
    <div class="sign-in">
      <div style="
              height: 100%;
              width: 100%;
              max-width: 400px;
              margin-bottom: 61px;
              padding: 5rem 1rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-direction: column;
            ">

        <img src="../../../assets/img/Logo.jpeg" width="135px" height="auto" style="margin-top: -4px; margin-bottom: 10px; padding-bottom: 30px; padding-top: 30px;
              " />
        <mat-h5 style="text-align: center; margin-bottom: 4rem; font-weight: 700;font-size: 30px; margin-top: -35px;
              ">
          Sign In
        </mat-h5>
        <form [formGroup]="formGroup" id="LoginForm" class="LoginForm">
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Email Address<Address></Address></mat-label>
            <input type="text" placeholder="Email Address" formControlName="username" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Password</mat-label>
            <input [type]="passwordHide ? 'password' : 'text'" formControlName="password" placeholder="Password"
                   matInput />
          </mat-form-field>
          <br />

          <button mat-stroked-button color="success" style="width: 100%; margin-top: 1rem; background-color: green;"
                  (click)="onSubmit(formGroup.value)">
            <p style="color:white">LogIn</p>
          </button>
          <button mat-stroked-button color="" style="width: 100%; margin-top: 1rem;background-color: red;"
                  (click)="forgotPassword()">
            <p style="color:white">Forgot Password</p>
          </button>
        </form>
      </div>
    </div>
  </div>
  <div id="right">
    <div class="showcase">
      <div class="showcase-content">
      </div>
    </div>
  </div>
</div>
<!-- <app-example-pdf-viewer></app-example-pdf-viewer> -->
