import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiManagerService } from 'src/app/api-manager/api-manager.service';

@Component({
  selector: 'app-edit-meeting',
  templateUrl: './edit-meeting.component.html',
  styleUrls: ['./edit-meeting.component.css']
})
export class EditMeetingComponent implements OnInit {

  formGroup: FormGroup;
  meeting: any = {};
  users: any = [];
  loading = false;
  title = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditMeetingComponent>,
    private formBwilder: FormBuilder,
    private apiManager: ApiManagerService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    console.log("our meeting is ---", this.data.meeting)
    this.meeting = this.data.meeting;
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBwilder.group({
      meeting_title: [this.meeting?.meeting_title, [Validators.required]],
      start_date: [this.meeting?.start_date, [Validators.required]],
      end_date: [this.meeting?.end_date, [Validators.required]],
      meeting_address: [this.meeting?.meeting_address, [Validators.required]],
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
  onSubmit(data: any) {
    const spinner = this.apiManager.startLoading('Updating meeting..');
    console.log("meeting id is", this.meeting.id)
    console.log("data is ---", data)
    const meeting_id = this.meeting.id;
    this.loading = true;
    console.log(meeting_id, data);
    this.apiManager.updateMeeting(meeting_id, data).subscribe({
      next: (res) => {
        this.apiManager.stopLoading(spinner);
        this.loading = false;
        this.dialogRef.close(res);
        this.openSnackBar('Meeting updated successfully', 'Close');
      },
      error: (err) => {
        this.apiManager.stopLoading(spinner);
        console.log(err);
        this.loading = false;
        this.openSnackBar('Oops! Error while updating meeting!', 'Close');
      },
    });
  }

}
