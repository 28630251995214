<br>
<br>
<br>

<div class="page" *ngIf="!notsigned">
  <div class="profile_2">
    <div class="heading-section">
      <div>
        <button mat-icon-button (click)="onClose()" color="primary">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
      </div>
      <h2>Sign Document</h2>
    </div>
    <br />
    <button mat-button class="save-btn" [disabled]="signatureadded" (click)="addsignature()">
      <mat-icon>check_circle</mat-icon>
      Add Signature
    </button>
    <br>
    <br>
    <button mat-button class="save-btn" [disabled]="!signatureadded" (click)="export()">
      <mat-icon>check_circle</mat-icon>
      Complete signing
    </button>
    <br />
    <mat-hint color="warn" class="example-full-width"
      >You only allowed to sign once so make sure you sign in right
      position</mat-hint
    >
    <br>
    <br>
    <mat-hint *ngIf="addsignaturetopdf" color="warn" class="example-full-width">
      click on the draw tool and draw your signature or click on the text tool to add signature then finish signing
    </mat-hint>
  </div>

  <!-- <div #viewer class="viewer"></div> -->
  <!-- <div *ngIf="addsignaturetopdf">
    <p>
      click on the draw tool and draw your signature then finish signing
    </p>
  </div> -->

  <ngx-extended-pdf-viewer 
    class="viewer"
    [src]="pdfSrc" 
    [showToolbar]="showToolbar"
    [showHandToolButton]="true" 
    [showBookmarkButton]="true" 
    [showDownloadButton]="true" 
    [showPrintButton]="true" 
    [showSidebarButton]="true" 
    [showFindButton]="true" 
    [showPagingButtons]="true" 
    [showZoomButtons]="true" 
    [showPresentationModeButton]="true" 
    [showOpenFileButton]="true" 
    [showSecondaryToolbarButton]="true" 
    [showRotateButton]="true" 
    [showScrollingButton]="true" 
    [useBrowserLocale]="true" 
    [textLayer]="true" 
    [annotationLayer]="true" 
    [renderInteractiveForms]="true" 
    [printResolution]="150" 
    [maxCanvasPixels]="10485760">

  </ngx-extended-pdf-viewer>
</div>

<div class="view-doc" *ngIf="notsigned">
  <contract-document-viwer class="viewer" [pdfSrc] = "pdfSrc"></contract-document-viwer>
</div>
