<br>
<br>
<br>

<h1 mat-dialog-title>Thank you for taking this Action</h1>
<div mat-dialog-content>{{data}}</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    <mat-icon color="primary">done_outline</mat-icon>
  </button>
</div>
