import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-actions-control',
  templateUrl: './actions-control.component.html',
  styleUrls: ['./actions-control.component.css']
})
export class ActionsControlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
