import { Component, ElementRef, OnInit, ViewChild,Inject } from '@angular/core';
import {PDFAnnotate} from '../../assets/js/pdfannotate.js';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';

export interface AnnotateData {
  url:any;
}

// import{} from 'pd'
@Component({
  selector: 'app-pdfannotate',
  templateUrl: './pdfannotate.component.html',
  styleUrls: ['./pdfannotate.component.css']
})
export class PdfannotateComponent implements OnInit {
file:any
pdf:any
showToolbar = true
pdfSrc = "data:application/pdf;base64,JVBERi0xLjQKJfbk/N8KMSAwIG9iago8PAovVHlwZSAvQ2F0YWxvZwovVmVyc2lvbiAvMS40Ci9QYWdlcyAyIDAgUgovVmlld2VyUHJlZmVyZW5jZXMgMyAwIFIKL0xhbmcgKGVuKQo+PgplbmRvYmoKNCAwIG9iago8PAovQ3JlYXRvciAoQ2FudmEpCi9Qcm9kdWNlciAoQ2FudmEpCi9DcmVhdGlvbkRhdGUgKEQ6MjAyMzExMDIxMDMwNTUrMDAnMDAnKQovTW9kRGF0ZSAoRDoyMDIzMTEwMjEwMzA1NCswMCcwMCcpCi9LZXl3b3JkcyAoREFGekF0bURqWmMsQkFEMUFKd2Y4aGspCi9BdXRob3IgKGdlb3JnZSBrYXJlbWEpCi9UaXRsZSAoVW50aXRsZWQgZGVzaWduKQo+PgplbmRvYmoKMiAwIG9iago8PAovVHlwZSAvUGFnZXMKL0tpZHMgWzUgMCBSXQovQ291bnQgMQo+PgplbmRvYmoKMyAwIG9iago8PAovRGlzcGxheURvY1RpdGxlIHRydWUKPj4KZW5kb2JqCjUgMCBvYmoKPDwKL1R5cGUgL1BhZ2UKL1Jlc291cmNlcyA2IDAgUgovTWVkaWFCb3ggWzAuMCA3LjkyMDAwMjUgMTUwLjAgMTU3LjkyXQovQ29udGVudHMgNyAwIFIKL1BhcmVudCAyIDAgUgovVGFicyAvUwovQmxlZWRCb3ggWzAuMCA3LjkyMDAwMjUgMTUwLjAgMTU3LjkyXQovVHJpbUJveCBbMC4wIDcuOTIwMDAyNSAxNTAuMCAxNTcuOTJdCi9Dcm9wQm94IFswLjAgNy45MjAwMDI1IDE1MC4wIDE1Ny45Ml0KL1JvdGF0ZSAwCi9Bbm5vdHMgW10KPj4KZW5kb2JqCjYgMCBvYmoKPDwKL1Byb2NTZXQgWy9QREYgL1RleHQgL0ltYWdlQiAvSW1hZ2VDIC9JbWFnZUldCi9FeHRHU3RhdGUgOCAwIFIKPj4KZW5kb2JqCjcgMCBvYmoKPDwKL0xlbmd0aCAxMjgKL0ZpbHRlciAvRmxhdGVEZWNvZGUKPj4Kc3RyZWFtDQp4nMVNOw7CUAzbc4rMSA0vnxfaE3SmCwdA0Akkyv0l8lKpMLI1liU7sRwSHXKwBDr6sVxPNHDoHq8PeEELuNTkcoPLAZ+xVeLw7fZVEWdsmEZcxTLDcVSc39kiZWW03OEc2KVcyGovVSxrwxlbccXOSFlcxf/5Vj25ffsAeidDcA0KZW5kc3RyZWFtCmVuZG9iago4IDAgb2JqCjw8Ci9HMyA5IDAgUgo+PgplbmRvYmoKOSAwIG9iago8PAovY2EgMQovQk0gL05vcm1hbAo+PgplbmRvYmoKeHJlZgowIDEwCjAwMDAwMDAwMDAgNjU1MzUgZg0KMDAwMDAwMDAxNSAwMDAwMCBuDQowMDAwMDAwMzMwIDAwMDAwIG4NCjAwMDAwMDAzODcgMDAwMDAgbg0KMDAwMDAwMDExNCAwMDAwMCBuDQowMDAwMDAwNDMwIDAwMDAwIG4NCjAwMDAwMDA2OTQgMDAwMDAgbg0KMDAwMDAwMDc3OCAwMDAwMCBuDQowMDAwMDAwOTgwIDAwMDAwIG4NCjAwMDAwMDEwMTEgMDAwMDAgbg0KdHJhaWxlcgo8PAovUm9vdCAxIDAgUgovSW5mbyA0IDAgUgovSUQgWzxCRjg3RkUyRjc5RkM0ODIwM0REMDFDQzAzQzJBODUxQj4gPEJGODdGRTJGNzlGQzQ4MjAzREQwMUNDMDNDMkE4NTFCPl0KL1NpemUgMTAKPj4Kc3RhcnR4cmVmCjEwNTAKJSVFT0YK"
    
    @ViewChild('pdf-container')pdfcontainer:ElementRef
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AnnotateData,
    private http: HttpClient,
  ) { 
  // malert()
  }

  ngOnInit(): void {
    console.log("our data url is -------"+this.data.url);
    // this.file = FileSaver.saveAs(this.data.url);
    // console.log(this.file)
    this.http.get('assets/sample.pdf', { responseType: 'text' })
      .subscribe((data: any) =>{
        this.file = data
        console.log("file in assets folder is ----"+data)
      } );
      // this.pdf = new PDFAnnotate(this.pdfcontainer, this.file)
  }

  ngAfterViewInit(){
    this.pdf = new PDFAnnotate(this.pdfcontainer, '/assets/sample.pdf')
    

  }

}
