<br>
<br>
<br>

<div class="page">
  <div class="profile_2">
    <div class="heading-section">
      <div>
        <button mat-icon-button (click)="onClose()" color="primary">
          <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
      </div>
      <h2>View document</h2>
    </div>
    <br />
    <!-- <a [href]="fileUrl" download="resume.pdf"> -->
    <button mat-button class="save-btn" (click)="export()">
      <mat-icon>download_for_offline</mat-icon>
      Download
    </button>
  <!-- </a> -->
  </div>

  <!-- <div #viewer class="viewer"></div> -->
  <ngx-extended-pdf-viewer 
    class="viewer"
    [src]="pdfSrc" 
    [showToolbar]="false"
    [showHandToolButton]="true" 
    [showBookmarkButton]="true" 
    [showDownloadButton]="true" 
    [showPrintButton]="true" 
    [showSidebarButton]="true" 
    [showFindButton]="true" 
    [showPagingButtons]="true" 
    [showZoomButtons]="true" 
    [showPresentationModeButton]="true" 
    [showOpenFileButton]="true" 
    [showSecondaryToolbarButton]="true" 
    [showRotateButton]="true" 
    [showScrollingButton]="true" 
    [useBrowserLocale]="true" 
    [textLayer]="true" 
    [annotationLayer]="true" 
    [renderInteractiveForms]="true" 
    [printResolution]="150" 
    [maxCanvasPixels]="10485760">

  </ngx-extended-pdf-viewer>

</div>
