<div
  class="container-section"
  fxLayout="row"
  fxLayoutAlign="space-around center"
>
  <div [fxFlex]="(isHandset$ | async) ? '10' : '27.5'"></div>
  <div
    class="login-card-container"
    [fxFlex]="(isHandset$ | async) ? '80' : '45'"
  >
    <mat-card-header>
      <mat-card-title align="center"><b>Reset Password</b></mat-card-title>
      <mat-card-subtitle> to continue to BoardMeets</mat-card-subtitle>
    </mat-card-header>
    <mat-card>
      <mat-card-content>
        <form [formGroup]="formGroup" id="ResetForm" class="ResetForm">
          <strong>Your email</strong>
          <mat-form-field appearance="outline">
            <input type="text" formControlName="email" matInput />
          </mat-form-field>
          <strong>Reset code</strong>
          <mat-form-field appearance="outline">
            <input type="text" formControlName="reset_code" matInput />
          </mat-form-field>
          <strong>New Password</strong>
          <mat-form-field appearance="outline">
            <input
              [type]="passwordHide ? 'password' : 'text'"
              formControlName="new_password"
              matInput
            />
            <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
              passwordHide ? "visibility_off" : "visibility"
            }}</mat-icon>
          </mat-form-field>
          <br />
          <button
            mat-flat-button
            class="reset-btn"
            form="ResetForm"
            color="primary"
            [disabled]="!formGroup.valid"
            (click)="reset_password(formGroup.value)"
          >
            Reset Password
          </button>
        </form>
      </mat-card-content>
      <div class="for_btn" align="right">
        <a class="forgot-btn" routerLink="../forgot-password-page">Go back</a>
      </div>
    </mat-card>
  </div>
  <div [fxFlex]="(isHandset$ | async) ? '10' : '27.5'"></div>
</div>
