<div class="data" *ngIf="!docView && !isVideo">
  <div class="upper-section">
    <div class="middle-section">
      <div class="heading-section">
        <button mat-icon-button *ngIf="!isParent">
          <mat-icon class="pointer primary" (click)="_location.back()"
            >chevron_left</mat-icon
          >
        </button>

        <div class="heading-title">
          <strong>{{ parentFolderName }}</strong>
        </div>
      </div>

      <div class="btns">
        <button
          mat-button
          class="create-btn"
          (click)="createMainDialog()"
          *ngIf="isParent"
        >
          <mat-icon clolor="primary">add_circle</mat-icon>
          Add folder
        </button>
        <button
          mat-button
          class="create-btn"
          [matMenuTriggerFor]="menu2"
          #menuTrigger
          *ngIf="!isParent"
        >
          <mat-icon clolor="primary">add_circle</mat-icon>
          Add
        </button>
        <mat-menu #menu2="matMenu">
          <button mat-menu-item (click)="createSubFolderDialog()">
            <span>Add sub folder</span>
            <mat-icon color="primary">folder</mat-icon>
          </button>
          <button mat-menu-item (click)="createFileDialog()">
            <span>Add file</span>
            <mat-icon color="primary">article</mat-icon>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="content-data">
    <div class="example-full-width">
      <mat-form-field appearance="standard" *ngIf="isParent">
        <mat-select
          (selectionChange)="onFilterResourcesChange($event.value)"
          value="Created"
          name="folders"
        >
          <mat-option value="Permission"> Permission Resources </mat-option>
          <mat-option value="Created"> Your Resources </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="search-form" *ngIf="loadData">
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search folder"
          #input
        />
      </mat-form-field>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      *ngIf="loadData"
    >
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element" (click)="openFolder(element)">
          <div class="heading-section">
            <mat-icon>folder</mat-icon>
            <span class="heading-title">{{ element.name }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let element">
          <i (click)="openFolder(element)">{{
            element.created_at | date: "fullDate"
          }}</i>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            class="example-icon primary"
            aria-label="Example icon-button with share icon"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openFolder(element)">
              <span>Open</span>
              <mat-icon color="primary">launch</mat-icon>
            </button>
            <button mat-menu-item (click)="renameFolder(element)">
              <span>Rename</span>
              <mat-icon color="primary">drive_file_rename_outline</mat-icon>
            </button>
            <button mat-menu-item (click)="openPermissionDialog(element)">
              <span>Edit Permission</span>
              <mat-icon color="primary">edit</mat-icon>
            </button>
            <button mat-menu-item (click)="deleteFolder(element.id)">
              <span>Delete</span>
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>
    <h4 *ngIf="!loadData" align="center">No folders in this directory.</h4>
    <div *ngIf="hasDocuments">
      <mat-card class="example-card">
        <mat-list>
          <div mat-subheader>Files</div>
          <mat-list-item
            class="mat-list-item"
            *ngFor="let file of folderDocumentsObj"
          >
            <mat-icon (click)="openFile(file)" mat-list-icon color="warn"
              >file_present</mat-icon
            >
            <div mat-line>{{ file.doc_name }}</div>
            <div mat-line color="primary">{{ file.type }}</div>

            <button
              mat-icon-button
              [matMenuTriggerFor]="menu1"
              class="example-icon"
              aria-label="Example icon-button with share icon"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu1="matMenu">
              <button mat-menu-item (click)="openFile(file)">
                <span>Open</span>
                <mat-icon color="primary">launch</mat-icon>
              </button>
              <button mat-menu-item (click)="renameDocument(file)">
                <span>Rename</span>
                <mat-icon color="primary">drive_file_rename_outline</mat-icon>
              </button>
              <button mat-menu-item (click)="deleteDocument(file?.id)">
                <span>Delete</span>
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </mat-menu>
          </mat-list-item>
        </mat-list>
      </mat-card>
    </div>
  </div>
</div>

<div class="video-player-wrapper" *ngIf="isVideo">
  <button mat-button color="warn" (click)="isVideo = false">
    <mat-icon>close</mat-icon>
    Close
  </button>
  <app-videogular-player> </app-videogular-player>
</div>

<div *ngIf="docView">
  <button mat-button color="warn" (click)="docView = false">
    <mat-icon>close</mat-icon>
    Close
  </button>
  <h5 class="red-color" align="center">({{ docName }})</h5>
  <app-view-document [pdfSrc] = "src">

  </app-view-document>
</div>
