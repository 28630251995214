<div class="dialog">
  <div class="heading-section">
    <div>
      <button mat-icon-button (click)="onNoClick()">
        <mat-icon>arrow_back_ios_new</mat-icon>
      </button>
    </div>
    <div class="save-changes" *ngIf="currentUser?.org_permission == 'Admin'">
      <button mat-button color="warn" (click)="deleteAgenda(items.id)">
        Delete
      </button>

      <button
        mat-button
        (click)="onSubmit(formGroup.value)"
        color="primary"
        form="FormAgenda"
        [disabled]="!formGroup.valid"
      >
        Save Changes
      </button>

      <mat-spinner diameter="40" class="loading-indicator" *ngIf="loading">
      </mat-spinner>
    </div>
  </div>
  <br />

  <h2>{{items?.agenda_name}}</h2>

  <div
    mat-dialog-content
    [formGroup]="formGroup"
    id="FormAgenda"
    class="mat-dialog-content"
  >
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Section name</mat-label>
      <input matInput formControlName="agenda_name" />
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Section description</mat-label>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMinRows="1"
        matAutosizeMaxRows="20"
        formControlName="agenda_description"
      ></textarea>
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Permissions</mat-label>
      <mat-select
        class="mat-select-user"
        #usersList
        multiple
        formControlName="permission"
      >
        <mat-option *ngFor="let user of users" [value]="user.id"
          >{{ user.first_name }}  {{ user.last_name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Presenters</mat-label>
      <mat-select
        class="mat-select-user"
        multiple
        formControlName="presenters"
      >
      <mat-option *ngFor="let user of users" [value]="user.id"
          >{{ user.first_name }}  {{ user.last_name }}</mat-option
        >
    </mat-select>
    </mat-form-field> -->
    <div class="row">
      <div class="col-lg-10">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Presenters</mat-label>
          <input type="text"
                 placeholder="type presenter"
                 aria-label="presenter"
                 #presenter
                 matInput
                 [formControl]="presentersControl"
                 [matAutocomplete]="auto2">
                 
          <mat-autocomplete #auto2="matAutocomplete">
            
              <mat-option [value]="user" *ngFor="let user of presenterslist | async;">{{ user}}</mat-option>
            
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <button mat-button type="button"><mat-icon (click)="addpresenter(presenter.value)">add</mat-icon></button>
      </div>
    </div>
    <div *ngIf="prevpresenters">
      <div class="row"><strong>Presenter List</strong></div>
      <div  class="row" *ngFor="let f of prevpresenters">
        <div class="col-lg-10"><span>{{f}}</span></div>
        <div class="col-lg-2" ><a> <mat-icon (click)="onRemovePresenters(f)">delete</mat-icon> </a></div>
      </div>
    </div>
    <br>
    
    <!-- <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Guests</mat-label>
      <mat-select
        class="mat-select-user"
        multiple
        formControlName="guests"
      >
      <mat-option *ngFor="let user of users" [value]="user.id"
      >{{ user.first_name }} {{ user.last_name }}</mat-option
    >
    </mat-select>
    </mat-form-field> -->
    <div class="row">
      <div class="col-lg-10">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Guests</mat-label>
          <input type="text"
                 placeholder="type guest"
                 aria-label="guest"
                 #guest
                 matInput
                 [formControl]="guestsControl"
                 [matAutocomplete]="auto3"
                 >
                 
          <mat-autocomplete #auto3="matAutocomplete">
            
              <mat-option [value]="user" *ngFor="let user of guestslist | async;">{{user}}</mat-option>
            
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <button mat-button type="button"><mat-icon (click)="addguest(guest.value)">add</mat-icon></button>
      </div>
    </div>
    <div *ngIf="prevguests">
      <div class="row"><strong>Guests List</strong></div>
      <div  class="row" *ngFor="let f of prevguests">
        <div class="col-lg-10"><span>{{f}}</span></div>
        <div class="col-lg-2" ><a> <mat-icon (click)="onRemoveGuest(f)">delete</mat-icon> </a></div>
      </div>
    </div>
    <br>



    <div class="attachment-section">
      <ngx-dropzone (change)="onSelect($event)" [multiple]="true">
        <ngx-dropzone-label
          >Drag and Drop or click here to upload (word, excel, pdf, powerpoint, image)!</ngx-dropzone-label
        >
        <ngx-dropzone-preview
          *ngFor="let f of files"
          [removable]="true"
          (removed)="onRemove(f)"
        >
          <ngx-dropzone-label>{{ f.name }}</ngx-dropzone-label>
        </ngx-dropzone-preview> </ngx-dropzone
      ><br />
    </div>

    <div *ngIf="prevdocs">
      <div class="row"><strong>Previously Uploaded documents</strong></div>
      <div  class="row" *ngFor="let f of prevdocs">
        <div class="col-lg-10"><span>{{f.fields.document_name}}</span></div>
        <div class="col-lg-2" ><a> <mat-icon (click)="onRemovePreviousDoc(f)">delete</mat-icon> </a></div>
      </div>
    </div>
    <div>
      <div class="row"><strong>documents to upload</strong></div>
      <div class="row" *ngFor="let f of files">
        <div class="col-lg-2"><mat-icon>insert_drive_file</mat-icon></div>
        <div class="col-lg-8"><span>{{f.name}}</span></div>
        <div class="col-lg-2" ><a> <mat-icon (click)="onRemove(f)">delete</mat-icon> </a></div>
      </div>
    </div>
  </div>
</div>
