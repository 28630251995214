<div
  class="data"
  fxLayout
  fxLayout.xs="column"
  fxLayoutAlign="center"
  fxLayoutGap="20px"
  fxLayoutGap.xs="0"
>
  <div class="left-side" fxFlex>
    <div class="row">
      <div class="card">
        <ngx-shimmer-loading
          [shape]="'circle'"
          [width]="'70px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading
          [width]="'100%'"
          [height]="'20px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading [height]="'20px'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'70%'"></ngx-shimmer-loading>
      </div>

      <div class="card">
        <ngx-shimmer-loading
          [shape]="'rect'"
          [width]="'100%'"
          [height]="'120px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading
          [width]="'100%'"
          [height]="'20px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading [height]="'20px'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'70%'"></ngx-shimmer-loading>
      </div>

      <div class="card">
        <div class="d-flex-center">
          <ngx-shimmer-loading
            [shape]="'square'"
            [width]="'70px'"
          ></ngx-shimmer-loading>
          <div class="flex-1">
            <ngx-shimmer-loading
              [width]="'100%'"
              [height]="'20px'"
            ></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
          </div>
        </div>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'50%'"></ngx-shimmer-loading>
      </div>
    </div>
  </div>
  <div class="left-middle" fxFlex>
    <div class="row">
      <div class="card">
        <ngx-shimmer-loading
          [shape]="'circle'"
          [width]="'70px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading
          [width]="'100%'"
          [height]="'20px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading [height]="'20px'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'70%'"></ngx-shimmer-loading>
      </div>

      <div class="card">
        <ngx-shimmer-loading
          [shape]="'rect'"
          [width]="'100%'"
          [height]="'120px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading
          [width]="'100%'"
          [height]="'20px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading [height]="'20px'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'70%'"></ngx-shimmer-loading>
      </div>

      <div class="card">
        <div class="d-flex-center">
          <ngx-shimmer-loading
            [shape]="'square'"
            [width]="'70px'"
          ></ngx-shimmer-loading>
          <div class="flex-1">
            <ngx-shimmer-loading
              [width]="'100%'"
              [height]="'20px'"
            ></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
          </div>
        </div>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'50%'"></ngx-shimmer-loading>
      </div>
    </div>
  </div>
  <div class="right-side" fxFlex>
    <div class="row">
      <div class="card">
        <ngx-shimmer-loading
          [shape]="'circle'"
          [width]="'70px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading
          [width]="'100%'"
          [height]="'20px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading [height]="'20px'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'70%'"></ngx-shimmer-loading>
      </div>

      <div class="card">
        <ngx-shimmer-loading
          [shape]="'rect'"
          [width]="'100%'"
          [height]="'120px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading
          [width]="'100%'"
          [height]="'20px'"
        ></ngx-shimmer-loading>
        <ngx-shimmer-loading [height]="'20px'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'70%'"></ngx-shimmer-loading>
      </div>

      <div class="card">
        <div class="d-flex-center">
          <ngx-shimmer-loading
            [shape]="'square'"
            [width]="'70px'"
          ></ngx-shimmer-loading>
          <div class="flex-1">
            <ngx-shimmer-loading
              [width]="'100%'"
              [height]="'20px'"
            ></ngx-shimmer-loading>
            <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
          </div>
        </div>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
        <ngx-shimmer-loading [width]="'50%'"></ngx-shimmer-loading>
      </div>
    </div>
  </div>
</div>
